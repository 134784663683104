import React, { FC, ReactElement, useMemo } from "react";
import useViewModuleAccess from "~/hooks/access/useViewModuleAccess";
import { AccessModule } from "~/types/general.types";
import { Button } from "@ui/button";
import { ChevronRightIcon } from "lucide-react";
import { cn } from "~/utils/lib.utils";
import { Link, usePathname } from "~/i18n/routing";
import { useAppStateStore } from "~/stores/app-state";

interface TabBarMoreNavigationItemProps {
    icon: ReactElement;
    label: string;
    href?: string;
    module?: AccessModule;
}

const TabBarMoreNavigationItem: FC<TabBarMoreNavigationItemProps> = ({ href, icon, label, module }) => {
    const pathname = usePathname();
    const active = useMemo(() => {
        if (pathname === href) return true;

        return !!(href && pathname.startsWith(href));
    }, [href, pathname]);
    const { loading, allowed } = useViewModuleAccess(module);
    const { setIsMobileMoreMenuOpen } = useAppStateStore((store) => store);

    if (!href || loading || !allowed) return null;

    const handleClick = () => {
        setIsMobileMoreMenuOpen(false);
    };

    return (
        <Button
            asChild
            title={label}
            variant={active ? "default" : "outline"}
            size="lg"
            className="h-12 justify-start px-4"
        >
            <Link href={href ?? "#"} onClick={handleClick}>
                {React.cloneElement(icon, { className: cn("h-4 w-4 mr-3") })}
                <span className="-mb-0.5">{label}</span>
                <ChevronRightIcon className="ml-auto h-4 w-4 text-muted-foreground" />
            </Link>
        </Button>
    );
};

export default TabBarMoreNavigationItem;
