import { create } from "zustand";

type State = {
    isMobileMoreMenuOpen: boolean;
};

type Action = {
    setIsMobileMoreMenuOpen: (data: State["isMobileMoreMenuOpen"]) => void;
};

export const useAppStateStore = create<State & Action>((set) => ({
    isMobileMoreMenuOpen: false,

    setIsMobileMoreMenuOpen: (data: State["isMobileMoreMenuOpen"]) => set(() => ({ isMobileMoreMenuOpen: data })),
}));
