globalThis["__sentryRewritesTunnelPath__"] = "/sentry-monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"ecfd201931c5d83a62d34de235d5cfa6dd269d68"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import packageJson from "~/package.json";

Sentry.init({
    dsn: "https://5b5f82518a2402dd89ab22a841f85717@o4507368532017152.ingest.de.sentry.io/4507395414425680",
    enabled: process.env.NODE_ENV === "production",
    tracesSampleRate: 0.25,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
        Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    release: packageJson.version,
});
