import { ID } from "~/types/general.types";

export interface BasicCustomerData {
    id: ID;
    attributes: {
        name: string;
    };
}

export interface BasicCustomersListData {
    data: BasicCustomerData[];
}

export enum CustomersQueryKeys {
    CURRENT_CUSTOMER = "current_customer",
    BASIC_CUSTOMERS_LIST = "basic_customers_list",
}
