"use client";

import {
    BellIcon,
    BookUser,
    CircleHelpIcon,
    ClipboardListIcon,
    CpuIcon,
    GroupIcon,
    HomeIcon,
    Leaf,
    MapIcon,
    MenuIcon,
    PowerIcon,
    SettingsIcon,
    TruckIcon,
    UserIcon,
    Wrench,
} from "lucide-react";
import { signOut } from "next-auth/react";
import React, { FC, useContext } from "react";
import { AccessModule } from "~/types/general.types";
import TabBarMoreNavigationItem from "./TabBarMoreNavigationItem";
import TabBarNavigationItem from "./TabBarNavigationItem";
import { Card, CardContent } from "@ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@ui/avatar";
import { Button } from "@ui/button";
import { UserContext } from "~/context/User.context";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "@ui/drawer";
import { ScrollArea } from "@ui/scroll-area";
import { useTranslations } from "next-intl";
import { useAppStateStore } from "~/stores/app-state";

const TabBar: FC = () => {
    const t = useTranslations();
    const {
        state: { data: userData },
    } = useContext(UserContext);
    const { isMobileMoreMenuOpen, setIsMobileMoreMenuOpen } = useAppStateStore((store) => store);

    const handleToggleTabBar = (isOpen: boolean) => {
        setIsMobileMoreMenuOpen(isOpen);
    };

    return (
        <div className="flex h-16 w-full justify-between border border-t-gray-200 bg-white px-4 py-2 md:hidden">
            <TabBarNavigationItem href="/" exact={true} icon={HomeIcon} />
            <TabBarNavigationItem href="/map" icon={MapIcon} module={AccessModule.Map} />
            <TabBarNavigationItem href="/units" icon={TruckIcon} module={AccessModule.Units} />
            <TabBarNavigationItem href="/beacons" icon={CpuIcon} module={AccessModule.Beacons} />
            <TabBarNavigationItem onClick={() => handleToggleTabBar(true)} icon={MenuIcon} />

            <Drawer open={isMobileMoreMenuOpen} onOpenChange={handleToggleTabBar}>
                <DrawerContent>
                    <DrawerHeader>
                        <DrawerTitle>{t("more")}</DrawerTitle>
                    </DrawerHeader>
                    <ScrollArea className="h-[80dvh] px-4">
                        <div className="mb-10 flex flex-col gap-4">
                            <TabBarMoreNavigationItem
                                href="/alarms"
                                icon={<BellIcon />}
                                label={t("alarms")}
                                module={AccessModule.Alarms}
                            />
                            <TabBarMoreNavigationItem
                                href="/groups"
                                icon={<GroupIcon />}
                                label={t("groups")}
                                module={AccessModule.Groups}
                            />
                            <TabBarMoreNavigationItem
                                href="/reports/single"
                                icon={<ClipboardListIcon />}
                                label={t("reports")}
                                module={AccessModule.Reports}
                            />
                            <TabBarMoreNavigationItem
                                href="/service"
                                icon={<Wrench />}
                                label={t("service")}
                                module={AccessModule.Service}
                            />
                            <TabBarMoreNavigationItem
                                href="/co2"
                                icon={<Leaf />}
                                label={t("co2.headline")}
                                module={AccessModule.Co2}
                            />
                            <TabBarMoreNavigationItem
                                href="/people"
                                icon={<BookUser />}
                                label={t("employees")}
                                module={AccessModule.People}
                            />
                            <TabBarMoreNavigationItem
                                href="/cases"
                                icon={<CircleHelpIcon />}
                                label={t("cases")}
                                module={AccessModule.Cases}
                            />
                        </div>

                        <div role="separator" />

                        <div className="mb-10 flex flex-col gap-4">
                            <TabBarMoreNavigationItem
                                href="/settings/general"
                                icon={<SettingsIcon />}
                                label={t("settings")}
                            />
                            <TabBarMoreNavigationItem href="/profile" icon={<UserIcon />} label={t("profile")} />
                        </div>

                        <div role="separator" />

                        <Card className="mb-8 shadow-sm">
                            <CardContent className="flex items-center justify-between p-4">
                                <div className="flex items-center gap-2">
                                    <Avatar>
                                        <AvatarImage src={userData.avatar?.url ?? ""} />
                                        <AvatarFallback>{userData.firstName.substring(0, 1)}</AvatarFallback>
                                    </Avatar>
                                    <div className="flex flex-col space-y-1">
                                        <p className="text-sm font-medium leading-none">
                                            {`${userData.firstName} ${userData.lastName}`.trim()}
                                        </p>
                                        <p className="text-xs leading-none text-muted-foreground">{userData.email}</p>
                                    </div>
                                </div>

                                <Button
                                    onClick={() => signOut()}
                                    size="icon"
                                    className="h-10 w-10"
                                    variant="destructive"
                                >
                                    <PowerIcon className="h-5 w-5" />
                                </Button>
                            </CardContent>
                        </Card>
                    </ScrollArea>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default TabBar;
