"use client";

import { FC, PropsWithChildren, useContext, useEffect } from "react";
import { UserContext } from "~/context/User.context";
import { setUserData } from "~/actions/user.actions";
import { getImageUrl, sortAvatars } from "~/utils/strapi.utils";
import { UserDataResponse } from "~/types/user.types";
import posthog from "posthog-js";

interface UserDataTransferWrapperProps extends PropsWithChildren {
    userData: UserDataResponse;
}

// TODO: to get rid of this approach
export const UserDataTransferWrapper: FC<UserDataTransferWrapperProps> = ({ userData, children }) => {
    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        if (userData) {
            const {
                id,
                admin,
                avatar,
                collapsed_sidebar,
                customer,
                first_name,
                last_name,
                phone,
                phone_prefix,
                email,
                locale,
                user_group,
                simulate_customer_id,
            } = userData;
            const sortedAvatars = sortAvatars(avatar);
            const avatarThumbnailUrl = sortedAvatars?.[0]?.formats?.thumbnail.url ?? null;

            dispatch(
                setUserData({
                    admin: admin ?? false,
                    avatar: avatarThumbnailUrl
                        ? {
                              name: sortedAvatars?.[0]?.name ?? "",
                              url: getImageUrl(sortedAvatars?.[0], "thumbnail", true) ?? "",
                          }
                        : undefined,
                    collapsedSidebar: collapsed_sidebar ?? false,
                    customerId: customer?.id ?? 0,
                    firstName: first_name ?? "",
                    lastName: last_name ?? "",
                    phone: phone ?? "",
                    phone_prefix: phone_prefix ?? "",
                    email: email ?? "",
                    id: userData.id,
                    locale: locale ?? "da",
                    modules: user_group?.modules?.map(({ name }) => name) ?? [],
                    permissions: user_group?.permissions ?? [],
                    simulate_customer_id,
                }),
            );

            posthog.identify(id.toString(), { name: `${first_name} ${last_name}`.trim(), email });
        }
    }, []);

    return <>{children}</>;
};
