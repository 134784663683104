import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/app/posthog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src1467775075/src/web-app/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TableContextProvider"] */ "/codebuild/output/src1467775075/src/web-app/context/Table.content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/codebuild/output/src1467775075/src/web-app/context/User.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1467775075/src/web-app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1467775075/src/web-app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1467775075/src/web-app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/providers/apollo-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/providers/permissions-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/providers/posthog.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/providers/react-query-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/providers/session-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/styles/animations.scss");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1467775075/src/web-app/styles/globals.scss");
